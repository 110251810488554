import $ from 'jquery';
export const validationOptions = {
    ignore: '.ignore',
    focusInvalid: true,
    errorElement: 'span',
    errorClass: 'is-invalid',
    errorElementClass: 'is-invalid',
    errorPlacement(errors, element) {
        const $formGroup = $(element).closest('.form-group');
        const $labels = $formGroup.find('label');
        const invalidFeedback = $formGroup.find('.invalid-feedback');
        $formGroup.addClass('is-invalid');
        $labels.each((index, label) => {
            const $label = $(label);
            if ($label.closest('.form-group').is($formGroup)) {
                $label.addClass('is-invalid');
            }
        });
        if (invalidFeedback.length) {
            invalidFeedback.html(errors.html());
        }
        else {
            errors.appendTo(element.closest('.form-group')).addClass('invalid-feedback');
        }
    },
    unhighlight(element, errorClass) {
        const $formGroup = $(element).closest('.form-group');
        const $inputs = $formGroup.find('label, input, textarea, select');
        const $errors = $formGroup.find('.invalid-feedback');
        $formGroup.removeClass(errorClass);
        $inputs.each((index, input) => {
            const $input = $(input);
            if ($input.closest('.form-group').is($formGroup)) {
                $input.removeClass(errorClass);
            }
        });
        $errors.each((index, error) => {
            const $error = $(error);
            if ($error.closest('.form-group').is($formGroup)) {
                $error.remove();
            }
        });
    },
};
