import { getKeyByValue, getKeyByValueWithoutDates } from './object';
export const getEnglishErrorMessageByMessageText = (message) => {
    const translations = {
        en: require('@/translations/store_locator.en.json'),
        lv: require('@/translations/store_locator.lv.json'),
        ru: require('@/translations/store_locator.ru.json'),
    };
    let errorLvKey = getKeyByValue(translations.lv, message);
    let errorRuKey = getKeyByValue(translations.ru, message);
    let messageLv;
    let messageRu;
    if (!errorLvKey && !errorRuKey) {
        messageLv = getKeyByValueWithoutDates(translations.lv, message);
        messageRu = getKeyByValueWithoutDates(translations.ru, message);
        errorLvKey = messageLv?.key;
        errorRuKey = messageRu?.key;
    }
    if (!errorLvKey && !errorRuKey) {
        return message;
    }
    if (errorLvKey && messageLv?.date?.length === 1 && messageLv?.variables?.length === 1) {
        return translations.en[errorLvKey].replace(messageLv.variables[0], messageLv.date[0]);
    }
    if (errorRuKey && messageRu?.date?.length === 1 && messageRu?.variables?.length === 1) {
        return translations.en[errorRuKey].replace(messageRu.variables[0], messageRu.date[0]);
    }
    // @ts-ignore
    return translations.en[errorLvKey] || translations.en[errorRuKey];
};
