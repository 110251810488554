export const fieldErrorsToValidatorErrors = ($form, fieldErrors) => fieldErrors.reduce((errors, { fieldId, error }) => {
    let $field = $form.find(`[id^="${fieldId}"]`);
    if (!$field.length) {
        return errors;
    }
    if ($field.is('[type="radio"]')) {
        $field = $field.filter(':checked');
    }
    return {
        ...errors,
        [$field.attr('name')]: error,
    };
}, {});
