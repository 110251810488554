import LazyLoad from 'vanilla-lazyload/dist/lazyload';
export default class LazyImages {
    static lazyLoad;
    static lazyLoadBackground;
    static init() {
        this.lazyLoad = new LazyLoad({
            elements_selector: 'img.lazy',
        });
        this.lazyLoadBackground = new LazyLoad({
            elements_selector: '.js__bg-image.lazy',
            callback_enter: (el) => {
                el.classList.add('loaded');
            },
        });
    }
    static update() {
        this.lazyLoad.update();
    }
    static updateBackground() {
        this.lazyLoadBackground.update();
    }
}
