export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};
export const getKeyByValueWithoutDates = (object, value) => {
    const dateAndVariablesRegex = /\d{2}[\.]\d{2}[\.]\d{4}[\ ]\d{2}[\:]\d{2}[\:]\d{2}/g;
    const variablesRegex = /%([^%%]{1,})%/g;
    let variables;
    let messageKey;
    let date;
    Object.keys(object).find(key => {
        if (object[key].replace(variablesRegex, '') === value.replace(dateAndVariablesRegex, '')) {
            date = value.match(dateAndVariablesRegex);
            variables = object[key].match(variablesRegex);
            messageKey = key;
            return true;
        }
        return false;
    });
    return {
        date,
        variables,
        key: messageKey,
    };
};
