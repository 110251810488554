import $ from 'jquery';
import { hideButtonLoader, showButtonLoader } from '../utils/button-loader';
import CheckoutMessageProducts from './checkout-message-products';
import FormValidate from './form-validate';
import CartPopup from '../entries/shop-product/components/cart-popup';
import CartItemsCount from '../entries/shop-product/components/cart-items-count';
import Toast from './toasts';
import { ToastType } from '../types/toast';
import { fieldErrorsToValidatorErrors } from '../utils/field-errors';
import CheckoutMessage, { CheckoutMessageDataLayerEvent } from './checkout-message';
import GaTracking from '../core/components/ga-tracking';
export default class CheckoutMessageForm {
    static $form;
    static $submitButton;
    static defaultSubmitButtonText;
    static invalidSubmitButtonText;
    static isInCartPopup;
    static init($root = $('body'), isInCartPopup = false) {
        this.isInCartPopup = isInCartPopup;
        this.$form = $root.find('.js__checkout-message-form');
        if (!this.$form.length) {
            return;
        }
        this.$submitButton = this.$form.find(':submit');
        this.defaultSubmitButtonText = this.$submitButton.text();
        this.invalidSubmitButtonText = this.$submitButton.data('invalid-text');
        if (!this.getFormValidator()) {
            FormValidate.init($root);
        }
        this.getFormValidator().settings.submitHandler = () => {
            this.submitForm();
        };
        CheckoutMessageProducts.init($root);
        this.updateFormState();
    }
    static updateFormState() {
        this.toggleButtonState(this.$form.valid());
    }
    static getFormValidator() {
        return this.$form.data('validator');
    }
    static submitForm() {
        if (this.$submitButton.prop('disabled')) {
            return;
        }
        showButtonLoader(this.$submitButton);
        this.pushAddToCartDataLayerEvent(CheckoutMessageProducts.getProducts().first());
        CheckoutMessage.pushDataLayerEvent(CheckoutMessageDataLayerEvent.ButtonClick);
        $.ajax({
            type: this.$form.attr('method'),
            url: this.$form.attr('action'),
            data: this.$form.serialize(),
        }).then(({ quantity, content }) => {
            if (this.isInCartPopup) {
                CartItemsCount.set(quantity);
                CartPopup.populateAndOpenPopup(content);
                return;
            }
            window.location.reload();
        }, data => {
            hideButtonLoader(this.$submitButton);
            const fieldErrors = data.responseJSON?.errors?.errors;
            if (fieldErrors) {
                this.getFormValidator().showErrors(fieldErrorsToValidatorErrors(this.$form, fieldErrors));
            }
            Toast.callToast(ToastType.Error, this.$form.data('error-message'), this.$form.data('error-title'));
        });
    }
    static toggleButtonState(isValid) {
        this.$submitButton.text(isValid ? this.defaultSubmitButtonText : this.invalidSubmitButtonText);
        this.$submitButton.prop('disabled', !isValid);
    }
    static pushAddToCartDataLayerEvent($product) {
        const $selectedColor = CheckoutMessageProducts.getSelectedColor($product);
        GaTracking.pushDataLayer({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [
                        {
                            name: this.$form.data('product-name'),
                            id: $selectedColor.val(),
                            price: this.$form.data('product-price'),
                            brand: 'IQOS',
                            category: this.$form.data('product-category'),
                            variant: $selectedColor.data('name'),
                            quantity: '1',
                            dimension51: '',
                            dimension52: 'In Stock',
                            dimension54: '',
                            dimension55: '',
                            metric11: this.$form.data('product-metric'),
                        },
                    ],
                },
            },
        });
    }
}
