import $ from 'jquery';
const isMobile = () => /Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const contentLoader = ($content, state) => {
    const $loaderOverlay = $('<span class="js__content-spinner-overlay position-absolute content-spinner-overlay" role="status" aria-hidden="true"><span class="spinner-border text-primary-new" role="status" aria-hidden="true"></span></span>');
    if (state) {
        $content.prepend($loaderOverlay);
    }
    else {
        $content.find('.js__content-spinner-overlay').remove();
    }
};
const checkField = (selected, val, element) => {
    if (val && val.split(',').includes(selected)) {
        element.find('input:not(.js__ignore-field-toggle)').addBack().prop('disabled', false);
        element.find('select:not(.js__ignore-field-toggle)').addBack().prop('disabled', false);
        element.find('textarea:not(.js__ignore-field-toggle)').addBack().prop('disabled', false);
        element.show();
    }
    else {
        element.hide();
        element.find('input:not(.js__ignore-field-toggle)').addBack().prop('disabled', true);
        element.find('select:not(.js__ignore-field-toggle)').addBack().prop('disabled', true);
        element.find('textarea:not(.js__ignore-field-toggle)').addBack().prop('disabled', true);
    }
};
const makeFieldsReadOnly = (element) => {
    element.find('input:not(.js__ignore-field-toggle)').prop('readonly', true);
    element.find('select:not(.js__ignore-field-toggle)').prop('readonly', true);
    element.find('textarea:not(.js__ignore-field-toggle)').prop('readonly', true);
};
const toggleFields = (val, inputsWrappersSelectors, valueToCompare = '') => {
    inputsWrappersSelectors.forEach(inputWrapperSelector => {
        const $inputWrapper = $(inputWrapperSelector);
        checkField(val, valueToCompare ? valueToCompare : $inputWrapper.data('value'), $inputWrapper);
    });
};
export default { isMobile, toggleFields, contentLoader, makeFieldsReadOnly };
