import $ from 'jquery';
import toastr from 'toastr';
import { ToastType } from '../types/toast';
export const toastOptions = {
    closeButton: true,
    toastClass: 'toast-container',
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-center',
    preventDuplicates: false,
    onclick: () => null,
    showDuration: 350,
    hideDuration: 350,
    closeDuration: 350,
    timeOut: 5000,
    closeHtml: '<button class="toast-close-button btn btn-light btn-small btn-circular"><i class="nbw-icon nbw-icon-close text-dark font-size-body"></i></button>',
    extendedTimeOut: 300,
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    escapeHtml: false,
};
export default class Toast {
    static $callToastOnLoadElements;
    static $callToastOnClickElements;
    static init() {
        this.$callToastOnLoadElements = $('.js__call-toast-on-load');
        this.$callToastOnClickElements = $('.js__call-toast-on-click');
        toastr.options = toastOptions;
        this.$callToastOnLoadElements.each((index, element) => {
            this.callToastByElement($(element));
        });
        this.$callToastOnClickElements.on('click', event => {
            this.callToastByElement($(event.currentTarget));
        });
    }
    static callToast(toastType, message, title, overrides) {
        if (toastType === ToastType.Success) {
            toastr.success(message, title, overrides);
        }
        else if (toastType === ToastType.Error) {
            toastr.error(message, title, overrides);
        }
    }
    static callToastByElement($element) {
        const toastType = $element.data('type');
        const message = $element.data('message');
        const title = $element.data('title');
        this.callToast(toastType, message, title);
    }
}
