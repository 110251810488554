import $ from 'jquery';
import Navbar from '../components/navbar';
export const scrollToElement = ($element, offset = $(window).height() / 3) => {
    const targetOffsetTop = ($element.offset()?.top || 0) - offset - Navbar.getNavbarComputedHeight();
    const currentOffsetTop = $(window).scrollTop();
    const scrollTopDifference = currentOffsetTop < targetOffsetTop ? targetOffsetTop - currentOffsetTop : currentOffsetTop - targetOffsetTop;
    return new Promise(resolve => {
        $('html, body')
            .stop()
            .animate({
            scrollTop: targetOffsetTop,
        }, Math.max(scrollTopDifference / 4, 400), () => {
            resolve();
        });
    });
};
