import $ from 'jquery';
import CheckoutMessageForm from './checkout-message-form';
export default class CheckoutMessageProducts {
    static $products;
    static productSelector = '.js__checkout-message-product';
    static productImageSelector = '.js__checkout-message-product-image';
    static productColorSelectSelector = '.js__checkout-message-product-color-select';
    static init($root = $('body')) {
        this.$products = $root.find(this.productSelector);
        this.$products.each((index, product) => {
            const $product = $(product);
            $product.find(this.productColorSelectSelector).on('change', () => {
                this.handleColorChange($product);
                CheckoutMessageForm.updateFormState();
            });
            this.handleColorChange($product);
        });
    }
    static getProducts() {
        return this.$products;
    }
    static getSelectedColor($product) {
        return $product.find(this.productColorSelectSelector).filter(':checked');
    }
    static handleColorChange($product) {
        const $selectedColor = this.getSelectedColor($product);
        this.setProductImage($product, $selectedColor.data('image'));
    }
    static setProductImage($product, url) {
        $product.find(this.productImageSelector).attr('src', url);
    }
}
