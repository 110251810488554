const BUTTON_SPINNER_TEMPLATE = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
const BUTTON_LARGE_SPINNER_TEMPLATE = '<span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>';
const BUTTON_INNER_WRAPPER_TEMPLATE = '<span class="text-transparent"></span>';
export const showButtonLoader = ($button, isLargeLoader = false) => {
    $button
        .wrapInner(BUTTON_INNER_WRAPPER_TEMPLATE)
        .prepend(isLargeLoader ? BUTTON_LARGE_SPINNER_TEMPLATE : BUTTON_SPINNER_TEMPLATE)
        .addClass('loading')
        .prop('disabled', true);
};
export const hideButtonLoader = ($button) => {
    $button.find('.spinner-border').remove();
    $button.children().contents().unwrap();
    $button.prop('disabled', false).removeClass('loading');
};
export const toggleButtonLoader = ($button, state, isLargeLoader = false) => {
    if (state) {
        showButtonLoader($button, isLargeLoader);
    }
    else {
        hideButtonLoader($button);
    }
};
