import $ from 'jquery';
import { enterTransition, leaveTransition } from '../utils/transition';
import CheckoutMessageForm from './checkout-message-form';
import CartPopup from '../entries/shop-product/components/cart-popup';
import GaTracking from '../core/components/ga-tracking';
import GaPromotionTracking from '../core/components/ga-promotion-tracking';
export var CheckoutMessageDataLayerEvent;
(function (CheckoutMessageDataLayerEvent) {
    CheckoutMessageDataLayerEvent["Show"] = "checkoutMessageShow";
    CheckoutMessageDataLayerEvent["Dismiss"] = "checkoutMessageDismiss";
    CheckoutMessageDataLayerEvent["ButtonClick"] = "checkoutMessageButtonClick";
})(CheckoutMessageDataLayerEvent || (CheckoutMessageDataLayerEvent = {}));
export default class CheckoutMessage {
    static $message;
    static $messageBody;
    static $messageButton;
    static $messageClose;
    static isInCartPopup;
    static init($root = $('body'), isInCartPopup = false) {
        this.isInCartPopup = isInCartPopup;
        this.$message = $root.find('.js__checkout-message');
        if (!this.$message.length) {
            return;
        }
        this.$messageBody = this.$message.find('.js__checkout-message-body');
        this.$messageButton = this.$message.find('.js__checkout-message-button');
        this.$messageClose = this.$message.find('.js__checkout-message-close');
        CheckoutMessageForm.init(this.$message, isInCartPopup);
        if (isInCartPopup) {
            GaPromotionTracking.init(this.$message, true);
        }
        this.initCollapse();
        this.pushDataLayerEvent(CheckoutMessageDataLayerEvent.Show);
        this.$messageClose.on('click', this.close.bind(this));
        this.$messageButton.on('click', this.pushDataLayerEvent.bind(this, CheckoutMessageDataLayerEvent.ButtonClick));
        enterTransition(this.$message);
    }
    static pushDataLayerEvent(event) {
        GaTracking.pushDataLayer({
            event,
            checkoutMessageType: this.$message.data('message-type'),
            checkoutMessageTitle: this.$message.data('message-title'),
            checkoutMessageDisplayIn: this.$message.data('message-display-in'),
            checkoutMessageProduct: this.$message.data('message-product') || '',
        });
    }
    static async close() {
        $.post(this.$message.data('dismiss-url'));
        this.pushDataLayerEvent(CheckoutMessageDataLayerEvent.Dismiss);
        await leaveTransition(this.$message);
        this.$message.remove();
    }
    static initCollapse() {
        this.$messageBody.collapse({
            toggle: false,
        });
        this.$message.addClass('collapsed');
        this.$message.on('click', event => {
            if (!this.$messageBody.is(':visible')) {
                this.$message.off(event);
                this.$messageBody.on('show.bs.collapse', () => {
                    this.$message.removeClass('collapsed');
                });
                this.$messageBody.on('shown.bs.collapse', () => {
                    this.$messageBody.collapse('dispose');
                });
                this.$messageBody.collapse('show');
                if (this.isInCartPopup) {
                    CartPopup.disableAutoClose();
                }
            }
        });
    }
}
